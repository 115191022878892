<template>
  <v-toolbar-items>
    <v-btn v-if="!!id" text class="nav-button" color="#000" @click="goBack">
      <v-icon left color="#000">mdi-arrow-left</v-icon>
      Back
    </v-btn>
    <v-menu offset-y v-if="me.is.superAdmin || me.is.transportationAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#000" text v-bind="attrs" v-on="on" class="text-capitalize">
          Operations <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <template v-for="(operation, index) in operations">
          <v-list-item v-if="operation.show" :key="index" @click="operation.onClick">
            <v-list-item-title>{{ operation.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DriverListMenu',
  inject: ['eventHub'],
  components: {},
  props: ['id'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me']),
    operations() {
      return [
        { name: 'Driver Table', onClick: this.openDriverTable, show: true },
        { name: 'Driver Expiration Dates', onClick: this.openDriverExpDates, show: true },
        { name: 'Driver Events', onClick: this.openDriverEvents, show: true },
      ];
    },
  },
  methods: {
    goBack() {
      this.$router.push('/driver');
    },
    openDriverTable() {
      this.eventHub.$emit('openDriverTable');
    },
    openDriverExpDates() {
      this.eventHub.$emit('openDriverExpDates');
    },
    openDriverEvents() {
      this.eventHub.$emit('openDriverEvents');
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
  align-items: center;
}
</style>
